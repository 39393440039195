<template>
<div>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12" class="pb-2 px-1">
      <!-- FILTROS -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <div>
            <v-btn
              style="position: absolute; top: -15px; right: 55px; z-index: 1"
              color="success"
              title="Nuevo Feriado"
              small
              fab
              @click="nuevoBancos()"
            >
              <v-icon>fas fa-plus</v-icon>
            </v-btn>
          </div>
          <v-expansion-panel-header class="py-0 px-4 text-h5">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row class="pt-2 d-flex justify-space-between">
                <v-col cols="12" sm="6" md="6">
                  <v-row>
                    <v-col cols="12" sm="6" md="6" class="pt-1">
                      Bancos
                      <v-autocomplete
                        v-model="filtro.id_banco"
                        item-text="banco_nombre"
                        item-value="id_banco"
                        tabindex="1"
                        :items="bancosFiltro"
                        hide-details
                        outlined
                        dense
                        clearable 
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="pt-1">
                      <v-switch
                        v-model="filtro.inhabilitado"
                        label="Inhabilitado"
                        class="mb-md-2"
                        tabindex="1"
                        :true-value="1"
                        :false-value="0"
                        hide-details
                        dense
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="d-flex justify-end">
                  <BtnFiltroVue
                    :loading="load"
                    @action="buscar()"
                    clase="mt-0"
                    @clear="limpiarFiltros()"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <!-- DETALLES -->
    <v-col cols="12" class="elevation-2 px-1">
      <v-data-table
        class="cebra mt-2 elevation-1"
        :headers="header"
        :items="bancos"
        :loading="load"
        :search="search"
        sort-by="banco_nombre"
        :footer-props="{'items-per-page-options':[10, 15, 50, 100]}"
        dense
      >
        <!-- Busqueda -->
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-3" no-gutters>
            <v-col cols="6" sm="3" md="2">
              <SearchDataTableVue
                v-model="search"
              />
            </v-col>
          </v-row>
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
        <template v-slot:[`item.acciones`]="{item}">
          <v-tooltip top>
            <template  v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                color="orange"
                small
                @click="editar(item)"
              >
                fas fa-edit
              </v-icon>
            </template>
            <span> Editar </span>
          </v-tooltip>
          <!-- <v-tooltip bottom >
            <template v-slot:activator="{ on }">
              <v-icon
                class="px-2"
                @click="eliminar(item)"
                v-on="on"
                color="error"
                small
              >
                fas fa-times
              </v-icon>
            </template>
            <span> Eliminar </span>
          </v-tooltip> -->
        </template>
        <template v-slot:[`item.inhabilitado`]="{item}">
          <v-icon
            v-if="item.inhabilitado == 1"
            color="success"
            small
          >
            fas fa-check-circle
          </v-icon>
        </template>
      </v-data-table> 
    </v-col>
  </v-row>
  <ModalNvoEditarBancoPaisVue 
    v-model="objModal.activo"
    :datos="objModal"
    @guardarCambios="guardarCambios"
  />
</div>
</template>

<script>
import ModalNvoEditarBancoPaisVue from '../../components/auditoria/ModalNvoEditarBancoPais.vue';
import BtnFiltroVue from '../../components/util/BtnFiltro.vue';
import SearchDataTableVue from '../../components/util/SearchDataTable.vue';
import { order_list_by } from '../../util/utils';

export default {
  name: 'IndexBancosPais',
  data(){
    return{
      panel: 0,
      load: false,
      search: '',
      filtro:{
        id_banco: null,
        banco_nombre: null,
        inhabilitado: 0,
      },
      header:[
        { text: 'Banco' , value: 'banco_nombre' },
        //{ text: 'Inhabilitado', value:'inhabilitado', align: 'center'},
        { text: 'Acciones', value: 'acciones', filtreable: false, sorteable: false, align: 'center' }
      ],
      bancos:[],
      bancosFiltro: [],
      objModal: {
        activo: false,
        nuevo: false,
        banco: {
          id_banco: 0,
          banco_nombre: null,
          inhabilitado: 0,
        },
      }
    }
  },
  created(){
    this.init();
  },
  methods:{
    async init(){
      this.$store.state.loading = true;
      const res = await this.$store.dispatch('bancos/iniciarFormularioBancos')
      this.$store.state.loading = false;

      if(res.resultado == 0){
        return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error' })
      }
      this.bancosFiltro = res.bancos;
    },
    async buscar(){
      this.load = true;
      this.bancos = [];
      this.search = '';

      this.$store.state.loading = true;
      const res = await this.$store.dispatch('bancos/buscarBancosPais', this.filtro)
      this.$store.state.loading = false;
      this.load = false;

      if(res.resultado == 0){
        return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error', })
      }
      this.bancos = res.bancos;
    },
    limpiarFiltros(){
      this.bancos = [];
      this.search = '';
      this.filtro = {
        id_banco: null,
        banco_nombre: null,
        inhabilitado: 0,
      }
    },
    nuevoBancos(){
      let bancoAux = {
        id_banco: 0,
        banco_nombre: null,
        inhabilitado: 0,
      };
      this.objModal.banco = bancoAux;
      this.objModal.nuevo = true;
      this.objModal.activo = true;
    },
    editar(item){
      this.objModal.banco = item;
      this.objModal.nuevo = false;
      this.objModal.activo = true;
    },
    async guardarCambios(item, nuevo){
      item.inhabilitado = item.inhabilitado == null ? 0 : item.inhabilitado;
      this.$store.state.loading = true;
      const res = await this.$store.dispatch(nuevo? 'bancos/nuevoBanco' : 'bancos/actualizarBanco', item)
      this.$store.state.loading = false;

      if(res.resultado == 0){ return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error', }); }
      
      this.$swal.fire({
        icon: 'success',
        title: nuevo ? 'Banco creado' : 'Banco actualizado',
        text: nuevo ? 'Se creo el banco con éxito' : 'Se actualizó correctamente el banco.',
        timer: 3000
      });
      
      if(nuevo){
        this.bancos = [];
        item.id_banco = res.id_banco;
        item.banco_nombre = item.banco_nombre.toUpperCase();
        this.bancos.push(item)
        this.bancosFiltro.push({ banco_nombre: item.banco_nombre.toUpperCase(), id_banco: item.id_banco })
        order_list_by(this.bancosFiltro, 'banco_nombre')
      }else{
        let pos = this.bancos.map(e => e.id_banco).indexOf(item.id_banco);
        Object.assign(this.bancos[pos], item);
        pos = this.bancosFiltro.map(e => e.id_banco).indexOf(item.id_banco);
        Object.assign(this.bancosFiltro[pos], { banco_nombre: item.banco_nombre.toUpperCase(), id_banco: item.id_banco });
      }
      this.objModal.activo = false;
    },
    async eliminar(item){
      const resp = await this.$swal.fire({
        icon: 'warning',
        title: `Está seguro de eliminar el Banco País: ${item.banco_nombre}. ¿Confirma esta acción?`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      });
      if(resp.isConfirmed){
        item.inhabilitado = item.inhabilitado == null ? 0 : item.inhabilitado;
        this.$store.state.loading = true;
        const res = await this.$store.dispatch('bancos/eliminarBanco', { id_banco: item.id_banco })
        this.$store.state.loading = false;

        if(res.resultado == 0){ return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error', }); }
        
        this.$swal.fire({
          icon: 'success',
          title: 'Banco País eliminado.',
          text: 'Se eliminó el Banco con éxito.',
          timer: 3000
        });

        let pos = this.bancos.indexOf(item);
        this.bancos.splice(pos,1);
      }
    },
  },
  computed:{

  },
  components:{
    BtnFiltroVue, SearchDataTableVue, ModalNvoEditarBancoPaisVue
  },
  watch:{

  }
}
</script>

<style>

</style>